<template>
  <div class="userinfo">
    <div class="userinfo_header">
      <p @click="backUserInfo">个人信息</p>
      <span v-show="this.show.setpwdShow">
        <i class="el-icon-arrow-right"></i>
        &emsp;设置密码
      </span>
      <span v-show="this.show.changepwdShow">
        <i class="el-icon-arrow-right"></i>
        &emsp;修改密码
      </span>
    </div>
    <div
      class="userinfo_main"
      v-show="!this.show.setpwdShow && !this.show.changepwdShow"
    >
      <div class="head userinfo">
        <p class="main_title">头像</p>
        <p class="photo">
          <img :src="this.userInfo.avatarUrl" alt="" />
          <img
            v-show="this.userInfo.vipStatus"
            class="vip_img"
            src="../assets/image/vip/VIP_avatarUrl_person (1).png"
            alt=""
          />
          <!-- <img
            v-show="this.userInfo.vipLevel == 2 && this.userInfo.vipStatus"
            class="vip_img"
            src="../assets/image/vip/VIP_avatarUrl_person (2).png"
            alt=""
          />
          <img
            v-show="this.userInfo.vipLevel == 3 && this.userInfo.vipStatus"
            class="vip_img"
            src="../assets/image/vip/VIP_avatarUrl_person (3).png"
            alt=""
          /> -->
        </p>
      </div>

      <div class="userinfo memberOverTimne" v-if="this.userInfo.vipStatus">
        <p class="main_title">会员</p>
        <p class="memberOverTime">
          <span
            >{{
              this.userInfo.vipStatus
                ? this.userInfo.vipOverTime.slice(0, 10)
                : ""
            }}
            <b>到期</b></span
          >
          <router-link to="/membercenter">
            <span id="span">续费</span></router-link
          >
        </p>
      </div>
      <div class="account userinfo">
        <p class="main_title">账户</p>
        <p class="tel">{{ this.userInfo.phone }}</p>
      </div>
      <div class="sex userinfo">
        <p class="main_title">性别</p>
        <div class="sel_sex">
          <el-radio-group v-model="userInfo.sex">
            <el-radio :label="'男'">男</el-radio>
            <el-radio :label="'女'">女</el-radio>
            <el-radio :label="null">保密</el-radio>
          </el-radio-group>
          <i class="el-icon-error icon" v-show="this.tip_icon.sex_icon"></i>
        </div>
      </div>
      <div class="username userinfo">
        <p class="main_title">昵称</p>
        <div class="userinfo_inp">
          <input v-model="userInfo.nickName" type="text" />
        </div>
        <i
          class="el-icon-error icon"
          style="line-height: 5rem"
          v-show="this.tip_icon.username_icon"
        ></i>
      </div>
      <div class="birthday userinfo">
        <p class="main_title">生日</p>
        <div class="userinfo_inp">
          <el-date-picker
            style="width: 100%"
            v-model="userInfo.birthday"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <i
          class="el-icon-error icon"
          style="line-height: 5rem"
          v-show="this.tip_icon.birthday_icon"
        ></i>
      </div>
      <div class="occupation userinfo">
        <p class="main_title">职业</p>
        <div class="userinfo_inp">
          <!-- <el-select
            style="width: 100%"
            v-model="userInfo.job"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.occupation"
              :label="item.label"
              :value="item.occupation"
            >
            </el-option>
          </el-select> -->
          <input v-model="userInfo.job" type="text" />
        </div>
        <i
          class="el-icon-error icon"
          style="line-height: 5rem"
          v-show="this.tip_icon.job_icon"
        ></i>
      </div>
      <div class="password userinfo">
        <p class="main_title">密码</p>
        <div class="userinfo_pwd">
          <p>要求至少包含字母、符号或者数字中的两项长度超过8位</p>
          <div class="setPwd">
            <span @click="ShowSetPwd"> 设置&ensp;/&ensp;</span>
            <span @click="ShowSetPwd">修改</span>
          </div>
        </div>
      </div>
      <!-- <div class="cache userinfo">
        <p class="main_title">缓存</p>
        <div class="userinfo_cache">
          <p>
            当前缓存
            <b>482.1</b>
            MB ，定期清理有助于释放存储空间，提升效率
          </p>
          <div class="setCache">
            <span> 清理 </span>
          </div>
        </div>
      </div> -->
      <button @click="saveInfo" class="save">保存</button>
    </div>

    <!-- 设置密码 -->
    <div class="userinfo_setPwd" v-show="this.show.setpwdShow">
      <div class="set_pwd userpwd">
        <div class="set_pwd_set" style="margin-bottom: 0">
          <p>设置密码</p>
          <input type="password" v-model="userSetPwd.setPwd" />
        </div>
        <p
          class="pwd_tip"
          style="
            margin-bottom: 0.5rem;
            color: rgb(243, 162, 87);
            text-align: left;
            margin-left: 5.5rem;
          "
        >
          &emsp;密码必须由数字、字母、特殊字符这三个组成
        </p>
        <div class="set_pwd_save">
          <p>确认密码</p>
          <input type="password" v-model="userSetPwd.setPwdAg" />
        </div>
        <div class="set_pwd_save phone_send">
          <p class="p_phone_revampCode">
            将给手机号为{{ this.userInfo_phone }}的用户发送验证码
          </p>
        </div>
        <div class="set_pwd_save code">
          <p>验证码</p>
          &emsp;&ensp;
          <input
            style="width: 15.875rem"
            type="num"
            placeholder="请输入验证码"
            maxlength="6"
            v-model="userSetPwd.code"
          />
          <p class="p_code" v-show="!set_codeShow" @click="getCodeSetPwd">
            {{ this.set_codeText }}
          </p>
          <p class="p_code" v-show="set_codeShow">
            {{ this.set_time }}{{ this.set_hintText }}
          </p>
        </div>

        <div class="pwd_btn">
          <div class="btn_save" @click="setPwd">确定</div>
          <div class="btn_back" @click="backUserInfo">返回</div>
        </div>
      </div>
    </div>
    <!-- 修改密码 -->
    <div class="userinfo_setPwd" v-show="this.show.changepwdShow">
      <div class="revamp_pwd userpwd">
        <!-- <p class="p_title">修改密码</p> -->
        <!-- <div class="set_pwd_set">
          <p>原始密码</p>
          <input
            type="password"
            v-model="userRevampPwd.revampPwdOld"
            placeholder="请输入旧密码"
          />
        </div> -->
        <div class="set_pwd_save new" style="margin-bottom: 0">
          <p>新的密码</p>
          <input
            type="password"
            v-model="userRevampPwd.revampPwdNew"
            placeholder="请输入新密码"
          />
        </div>
        <p
          class="pwd_tip"
          style="
            margin-bottom: 0.5rem;
            color: rgb(243, 162, 87);
            text-align: left;
            margin-left: 5.5rem;
          "
        >
          &emsp;密码必须由数字、字母、特殊字符这三个组成
        </p>
        <div class="set_pwd_save new_ag">
          <p>确认密码</p>
          <input
            type="password"
            v-model="userRevampPwd.revampPwdAg"
            placeholder="请再次输入新密码"
          />
        </div>
        <div class="set_pwd_save phone_send">
          <p class="p_phone_revampCode">
            将给手机号为{{ this.userInfo_phone }}的用户发送验证码
          </p>
        </div>
        <div class="set_pwd_save code">
          <p>验证码</p>
          &emsp;&ensp;
          <input
            style="width: 15.875rem"
            type="num"
            placeholder="请输入验证码"
            maxlength="6"
            v-model="userRevampPwd.code"
          />
          <p class="p_code" v-show="!revamp_codeShow" @click="getCodeRevampPwd">
            {{ this.revamp_codeText }}
          </p>
          <p class="p_code" v-show="revamp_codeShow">
            {{ this.revamp_time }}{{ this.revamp_hintText }}
          </p>
        </div>
        <div class="pwd_btn">
          <div class="btn_save" @click="revampPwd">确定</div>
          <div class="btn_back" @click="backUserInfo">返回</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UpdateUserById, GetCode, UpdatePassword } from "../request/api";
// import moment from "moment";
export default {
  name: "App",
  data() {
    return {
      userInfo: {},
      userInfo_phone: "",
      userSetPwd: {
        setPwd: "",
        setPwdAg: "",
        code: "",
        event: "",
        tokenId: "",
        phone: "",
      },

      userRevampPwd: {
        // revampPwdOld: "",
        revampPwdNew: "",
        revampPwdAg: "",
        code: "",
        phone: "",
        event: "",
        tokenId: "",
      },
      //
      options: [
        {
          occupation: "黄金糕",
          label: "黄金糕",
        },
        {
          occupation: "双皮奶",
          label: "双皮奶",
        },
        {
          occupation: "蚵仔煎",
          label: "蚵仔煎",
        },
        {
          occupation: "龙须面",
          label: "龙须面",
        },
        {
          occupation: "北京烤鸭",
          label: "北京烤鸭",
        },
      ],

      //显示跳转
      show: {
        setpwdShow: false,
        changepwdShow: false,
      },

      //两个验证码倒计时
      //设置密码
      set_timer: null,
      set_time: 60,
      set_codeText: "获取验证码",
      set_hintText: "秒后重新发送",
      set_codeShow: false,
      //修改密码
      revamp_timer: null,
      revamp_time: 60,
      revamp_codeText: "获取验证码",
      revamp_hintText: "秒后重新发送",
      revamp_codeShow: false,

      //判断修改信息时是否会出现提示标志
      tip_icon: {
        sex_icon: false,
        job_icon: false,
        birthday_icon: false,
      },
    };
  },
  methods: {
    async saveInfo() {
      //保存做判断 强制做必填项判断
      if (this.userInfo.nickName) {
        if (
          this.userInfo.sex &&
          this.userInfo.nickName &&
          this.userInfo.birthday &&
          this.userInfo.job
        ) {
          // this.$message({ message: "保存成功!" });
          // for (let i in this.userInfo) {
          //   console.log(i + ":" + this.userInfo[i]);
          // }
          this.userInfo.birthday = new Date(this.userInfo.birthday).getTime();
          let updataInfo = {
            id: this.userInfo.id,
            nickName: this.userInfo.nickName,
            birthday: this.userInfo.birthday,
            job: this.userInfo.job,
            sex: this.userInfo.sex,
          };

          // 修改用户信息

          // 窗口判断
          this.$confirm("此操作将修改个人信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          })
            .then(async () => {
              // console.log(updataInfo);
              await UpdateUserById({ ...updataInfo }).then((res) => {
                if (res.code == 200) {
                  let info = this.$store.state.userinfo;
                  info.userInfo = { ...this.userInfo };
                  this.$store.commit("userInfo", { ...info });
                }
              });
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              for (let i in this.tip_icon) {
                this.tip_icon[i] = false;
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消修改",
              });
            });
        } else {
          this.tip_icon.sex_icon = !this.userInfo.sex ? true : false;
          this.tip_icon.job_icon = !this.userInfo.job ? true : false;
          this.tip_icon.birthday_icon = !this.userInfo.birthday ? true : false;
          this.$message({ message: "请把信息填写完整" });
        }
      } else {
        this.$message({
          message: "昵称不能为空!",
        });
      }
      //
    },
    revampPwd() {
      /**
       * TODO:1.检验输入密码  是否有密码和两次密码是否输入一致
       * TODO:2.检验是否输入验证码
       * TODO:3.发送请求，判断验证码是否合法
       */
      let testPwd =
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$/;
      if (this.userRevampPwd.revampPwdNew) {
        if (!testPwd.test(this.userRevampPwd.revampPwdNew)) {
          // alert("密码不合法");
          this.$message({
            message:
              "密码是一个大写字母或一个小写字母，一个数字以及一个特殊字符组成，长度8-20!",
            type: "warning",
          });
        } else {
          if (
            this.userRevampPwd.revampPwdNew == this.userRevampPwd.revampPwdAg
          ) {
            // alert("两次密码输入一致");
            if (this.userRevampPwd.code) {
              //最终成功代码
              // alert("验证码");
              this.$confirm("此操作修改您的密码, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true,
              })
                .then(async () => {
                  // console.log(this.userRevampPwd);
                  let obj = {
                    code: this.userRevampPwd.code,
                    event: this.userRevampPwd.event,
                    phone: this.userRevampPwd.phone,
                    tokenId: this.userRevampPwd.tokenId,
                    password: this.userRevampPwd.revampPwdNew,
                  };
                  await UpdatePassword(obj).then((res) => {
                    if (res.code == 200) {
                      //成功之后退出登录 删除缓存
                      Object.keys(this.userRevampPwd).forEach(
                        (key) => (this.userRevampPwd[key] = "")
                      );
                      this.$router.push("/");
                      localStorage.clear();
                      sessionStorage.clear();
                      this.$store.commit("userInfo", {});
                      this.$message({
                        type: "success",
                        message: "修改成功,请重新登录!",
                      });
                    }
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消修改",
                  });
                });
            } else {
              this.$message({
                message: "请输入验证码!",
                type: "warning",
              });
              // alert("请输入验证码");
            }
          } else {
            this.$message({
              message: "两次输入密码不一致!",
              type: "warning",
            });
            // alert("两次输入密码不一致");
          }
        }
      } else {
        this.$message({
          message: "请输入密码!",
          type: "info",
        });
        // alert("请输入密码");
      }

      // console.log(this.userRevampPwd);
    },
    setPwd() {
      // console.log(this.userSetPwd);
      /**
       * TODO:1.检验输入密码  是否有密码和两次密码是否输入一致
       * TODO:2.检验是否输入验证码
       * TODO:3.发送请求，判断验证码是否合法
       */
      let testPwd =
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$/;
      if (this.userSetPwd.setPwd) {
        if (!testPwd.test(this.userSetPwd.setPwd)) {
          // alert("密码不合法");
          this.$message({
            message:
              "密码是一个大写字母或一个小写字母，一个数字以及一个特殊字符组成，长度8-20",
            type: "warning",
            offset: 100,
          });
        } else {
          if (this.userSetPwd.setPwd == this.userSetPwd.setPwdAg) {
            // alert("两次密码输入一致");
            if (this.userSetPwd.code) {
              //最终成功代码
              // alert("验证码");
              this.$confirm("此操作设置您的密码, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true,
              })
                .then(async () => {
                  // console.log(this.userSetPwd);
                  let obj = {
                    code: this.userSetPwd.code,
                    event: this.userSetPwd.event,
                    phone: this.userSetPwd.phone,
                    tokenId: this.userSetPwd.tokenId,
                    password: this.userSetPwd.setPwd,
                  };
                  await UpdatePassword(obj).then((res) => {
                    if (res.code == 200) {
                      //成功之后退出登录 删除缓存
                      Object.keys(this.userSetPwd).forEach(
                        (key) => (this.userSetPwd[key] = "")
                      );
                      this.$router.push("/");
                      localStorage.clear();
                      sessionStorage.clear();
                      this.$store.commit("userInfo", {});
                      this.$message({
                        type: "success",
                        message: "设置成功,请重新登录!",
                      });
                    }
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消修改",
                  });
                });
            } else {
              this.$message({
                message: "请输入验证码!",
                type: "warning",
              });
              // alert("请输入验证码");
            }
          } else {
            this.$message({
              message: "两次输入密码不一致!",
              type: "warning",
            });
            // alert("两次输入密码不一致");
          }
        }
      } else {
        this.$message({
          message: "请输入密码!",
          type: "info",
        });
        // alert("请输入密码");
      }
    },

    backUserInfo() {
      this.show.setpwdShow = false;
      this.show.changepwdShow = false;
      // this.show.map((i) => {
      //   i == false;
      // });
    },
    ShowSetPwd() {
      if (this.userInfo_hasWpd) {
        this.userpwd = {};
        this.show.changepwdShow = true;
      } else {
        this.userpwd = {};
        this.show.setpwdShow = true;
      }
    },
    //两个验证码点击事件
    async getCodeRevampPwd() {
      let testPwd =
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$/;
      if (this.userRevampPwd.revampPwdNew) {
        if (this.userRevampPwd.revampPwdAg) {
          if (
            this.userRevampPwd.revampPwdAg === this.userRevampPwd.revampPwdNew
          ) {
            if (testPwd.test(this.userRevampPwd.revampPwdNew)) {
              this.revamp_codeShow = true;
              this.revamp_timer = setInterval(() => {
                this.revamp_time--;
                if (this.revamp_time < 1) {
                  clearInterval(this.revamp_timer);
                  this.revamp_codeText = "重新发送";
                  this.revamp_time = 60;
                  this.revamp_codeShow = false;
                }
              }, 1000);
              //获取验证码
              let revampCode = {
                phone: this.userInfo.phone,
                event: "revampCode",
              };
              await GetCode(revampCode).then((res) => {
                // console.log(res);
                this.$message({
                  message: "验证码已发送，请注意查收！",
                  type: "success",
                  offset: 100,
                });
                if (res.code == 200) {
                  this.userRevampPwd.tokenId = res.tokenId;
                }
              });
              this.userRevampPwd.phone = revampCode.phone;
              this.userRevampPwd.event = revampCode.event;
            } else {
              this.$message({
                message:
                  "密码是一个大写字母或一个小写字母，一个数字以及一个特殊字符组成，长度8-20",
                type: "warning",
                offset: 100,
              });
            }
          } else {
            this.$message({
              message: "两次输入密码不一致!",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "重复密码不能为空!",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "新的密码不能为空!",
          type: "warning",
        });
      }
      // console.log(this.userRevampPwd);
    },
    async getCodeSetPwd() {
      let testPwd =
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$/;
      if (this.userSetPwd.setPwd) {
        if (this.userSetPwd.setPwdAg) {
          if (
            this.userSetPwd.setPwd === this.userSetPwd.setPwdAg
          ) {
            if (testPwd.test(this.userSetPwd.setPwd)) {
              this.set_codeShow = true;
              this.set_timer = setInterval(() => {
                this.set_time--;
                if (this.set_time < 1) {
                  clearInterval(this.set_timer);
                  this.set_codeText = "重新发送";
                  this.set_time = 60;
                  this.set_codeShow = false;
                }
              }, 1000);
              let setCode = {
                phone: this.userInfo.phone,
                event: "setCode",
              };
              await GetCode(setCode).then((res) => {
                this.$message({
                  message: "验证码已发送，请注意查收！",
                  type: "success",
                  offset: 100,
                });
                if (res.code == 200) {
                  this.userSetPwd.tokenId = res.tokenId;
                }
              });
              this.userSetPwd.phone = setCode.phone;
              this.userSetPwd.event = setCode.event;
            } else {
              this.$message({
                message:
                  "密码是一个大写字母或一个小写字母，一个数字以及一个特殊字符组成，长度8-20",
                type: "warning",
                offset: 100,
              });
            }
          } else {
            this.$message({
              message: "两次输入密码不一致!",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "重复密码不能为空!",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "新的密码不能为空!",
          type: "warning",
        });
      }
    },
  },
  created() {
    this.userInfo = this.$store.state.userinfo.userInfo;
    // console.log(this.userInfo);
    this.userInfo_hasWpd = this.$store.state.userinfo.hasPassword;
    for (let i in this.userInfo.phone) {
      if (i >= 3 && i <= 6) {
        this.userInfo_phone += "*";
      } else {
        this.userInfo_phone += this.userInfo.phone[i];
      }
    }
  },
  mounted() {
    //解决this指向问题，在window.addEventListener中this是指向window的。
    //需要将vue实例赋值给_this,这样在window.addEventListener中通过_this可以为vue实例上data中的变量赋值
    let _this = this;
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", function (e) {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值
      // console.log("值改变了");
      if (e.key === "userInfo") {
        // console.log(e.newValue);
        _this.userInfo = JSON.parse(e.newValue);
        if (_this.userInfo.sex) {
          switch (_this.userInfo.sex) {
            case "男":
              _this.userInfo.sex = 1;
              break;
            case "女":
              _this.userInfo.sex = 2;
          }
        }
      }
    });
  },
};
</script>
<style lang='scss' scoped>
.icon {
  margin-left: 3.125rem;
  color: red;
}
.userinfo {
  .userinfo_header {
    width: 100%;
    height: 5.3125rem;
    box-sizing: border-box;
    padding: 1.75rem 0 1.125rem 2.375rem;
    border-bottom: 0.0625rem #ebedf2 solid;
    display: flex;
    p {
      font-size: 1.5rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #191f33;
      line-height: 2.0625rem;
      text-align: left;
      cursor: pointer;
    }
    span {
      font-size: 1.125rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191f33;
      line-height: 2.0625rem;
      margin-left: 1.25rem;
      i {
        color: #a4abc0;
        font-weight: 900;
      }
    }
  }
  .userinfo_main {
    box-sizing: border-box;
    padding: 1.75rem 0 0 2.5rem;
    .userinfo {
      display: flex;
      .main_title {
        font-size: 1.125rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
        line-height: 5rem;
        margin: 0 2rem 0 0;
        text-align: center;
      }
      .photo {
        width: 5rem;
        height: 5rem;
        // background-color: red;
        border-radius: 50%;
        // overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .vip_img {
          width: 5rem;
          height: 1.5rem;
          position: absolute;
          top: 3.75rem;
          left: 0;
        }
      }
      .memberOverTime {
        display: flex;
        height: 5rem;
        line-height: 5rem;
        #span {
          font-size: 1.125rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4673ff;
          cursor: pointer;
          b {
            font-weight: 500;
          }
        }
        span:first-child {
          font-size: 1.125rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c4c6cd;
          margin-right: 1.375rem;
        }
      }
      .tel {
        font-size: 1.125rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c4c6cd;
        line-height: 5rem;
      }
      .sel_sex {
        line-height: 5rem;
      }
      .userinfo_inp {
        width: 23.75rem;
        height: 2.5rem;
        margin-top: 1.25rem;
        overflow: hidden;
        border-radius: 0.25rem;
        input {
          width: 100%;
          height: 2.5rem !important;
          box-sizing: border-box;
          outline: none;
          padding: 0 1rem;
          font-size: 1.125rem;
          background-color: #fff;
          border: 0.0625rem solid #dcdfe6;
        }
        .el-input__inner {
          background-color: red;
          color: red;
        }
      }
      .userinfo_pwd {
        width: 27.8125rem;
        height: 3rem;
        display: flex;
        p {
          font-size: 0.875rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a4abc0;
          line-height: 1.5rem;
          text-align: left;
          box-sizing: border-box;
          padding-top: 0.9375rem;
        }
        .setPwd {
          width: 10.625rem;
          height: 100%;
          box-sizing: border-box;
          padding-top: 0.9375rem;
          span {
            font-size: 1.125rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4673ff;
            line-height: 1.1625rem;
            cursor: pointer;
          }
        }
      }
      .userinfo_cache {
        width: 27.8125rem;
        height: 3rem;
        display: flex;
        p {
          font-size: 0.875rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a4abc0;
          line-height: 1.5rem;
          text-align: left;
          box-sizing: border-box;
          padding-top: 0.9375rem;
          b {
            font-weight: normal;
            color: #4673ff;
          }
        }
        .setCache {
          width: 10.625rem;
          height: 100%;
          box-sizing: border-box;
          padding-top: 0.9375rem;
          span {
            font-size: 1.125rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4673ff;
            line-height: 1.1625rem;
            cursor: pointer;
          }
        }
      }
    }
    .save {
      width: 11.25rem;
      height: 3.125rem;
      background-color: #4673ff;
      border-radius: 0.5rem;
      float: left;
      margin-left: 4.375rem;
      margin-top: 1rem;
      font-size: 1.125rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 1.5625rem;
    }
  }

  .userinfo_setPwd {
    box-sizing: border-box;
    padding: 2.5rem;
    .phone_send {
      .p_phone_revampCode {
        color: #adaec8;
        margin-left: 3.125rem;
      }
    }
    .userpwd div {
      display: flex;
      margin-bottom: 1.25rem;
      input {
        outline: none;
        width: 23.75rem;
        height: 3.125rem;
        background: #f5f6fc;
        border-radius: 0.25rem;
        box-sizing: border-box;
        padding: 0 0 0 1.25rem;
        margin-left: 1.25rem;
        font-size: 1.125rem;
      }
      p {
        font-size: 1.125rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
        line-height: 3.125rem;
      }
      .p_code {
        margin-left: 1.25rem;
      }
    }
    .pwd_btn {
      margin-left: 4.375rem;
      div {
        width: 11.25rem;
        height: 3.125rem;
        border-radius: 0.5rem;
        margin-left: 1.25rem;
        font-size: 1.125rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 3.125rem;
        text-align: center;
        display: inline-block;
      }
      div:nth-child(2n) {
        box-sizing: border-box;
        border: 0.0625rem solid #ebedf2;
        color: #191f33;
      }
      div:first-child {
        background: #4673ff;
        color: #ffffff;
      }
    }

    .revamp_pwd .p_title {
      margin-left: 5.625rem;
      margin-bottom: 1.875rem;
      // margin-top: 8.75rem;
      text-align: left;
      font-size: 1.125rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fd5656;
    }
  }
}
</style>
